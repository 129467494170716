import { unwrapReference } from "./../services/store";
import type { StoreRecordInternals } from "./../services/store";
import { useRecords } from "@/stores/records";
import { update } from "~~/services/store";
import type { StoreRecord } from "~~/services/store";

import createSaveOperation from "./operations/createSaveOperation";
import createCreateOperation from "./operations/createCreateOperation";

import type { StoreRecordData } from "~/services/store";

export default function <T>(record: StoreRecordData<T>) {
	if (!record.internals.reference) {
		throw new Error(`Cannot save undefined record`);
	}

	if (record.internals.reloading) {
		throw new Error(`Cannot save record ${record.internals.reference} while it is reloading`);
	}

	if (record.internals.deleting) {
		throw new Error(`Cannot save record ${record.internals.reference} while it is deleting`);
	}

	const records = useRecords();

	if (record.internals.saved) {
		update(records, record.internals.reference, { internals: { saving: true } as StoreRecordInternals } as StoreRecord);

		const { collectionName, id } = unwrapReference(record.internals.reference);

		return createSaveOperation(collectionName, id);
	} else {
		record.internals.saving = true;

		return createCreateOperation<T>(record);
	}
}
