import { pushRecords, unwrapReference } from "./../../services/store";
import type { StoreRecordData, StoreRecordInternals } from "./../../services/store";

import { useRecords } from "@/stores/records";
import { useOperations } from "@/stores/operations";
import { StoreOperationTypes, update, StoreProviderTypes, toReference } from "~~/services/store";
import type { StoreOperation, StoreRecord, StoreSaveOperation } from "~~/services/store";

import { v4 as uuidv4 } from "uuid";
import { get, register } from "~/stores/tasks";

export default function <T>(record: StoreRecordData<T>): { operation: ComputedRef<StoreSaveOperation | undefined>; task: ComputedRef<Promise<boolean> | undefined> } {
	const nuxtApp = useNuxtApp();
	const restapi = nuxtApp.$restapi;
	const records = useRecords();
	const operations = useOperations();
	const logger = nuxtApp.$logger;

	const { collectionName, id } = unwrapReference(record.internals.reference);

	const reference = toReference(collectionName, id);

	// create computed reference once
	const operation = computed<StoreSaveOperation | undefined>(() => {
		return (
			operations.list
				.sort((a: StoreOperation, b: StoreOperation) => {
					return a.timestamp - b.timestamp;
				})
				.filter((item: StoreOperation) => item.type === StoreOperationTypes.create && item.ssr === process.server) as StoreSaveOperation[]
		).find((item) => item.reference === reference && item.processing);
	});

	// create computed task value
	let task = computed<Promise<boolean> | undefined>(() => {
		if (operation.value) {
			return get(operation.value.id);
		} else {
			return undefined;
		}
	});

	// if there is an ongoing operation already
	if (operation.value) {
		return { operation, task };
	}

	function worker() {
		const timestamp = Date.now();

		const operationId = operations.push({
			id: uuidv4(),
			reference: reference,
			type: StoreOperationTypes.create,
			processing: true,
			awaiters: [],
			timestamp: timestamp,
			ssr: process.server,
			provider: StoreProviderTypes.restapi,
		} as StoreSaveOperation);

		const task = restapi
			.createDocument(collectionName, record!)
			.then((document) => {
				pushRecords(records, [document], collectionName, { saved: true, reloading: false } as StoreRecordInternals);
				return true;
			})
			.catch((error) => {
				logger.error(error);
				operations.update(operationId, { error } as StoreSaveOperation);
				throw error;
			})
			.finally(() => {
				operations.update(operationId, { processing: false, elapsed_time: Date.now() - timestamp } as StoreSaveOperation);
			});

		register(operationId, task);
	}

	worker();

	return { operation, task };
}
